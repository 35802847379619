// ** React Imports
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

// ** Custom Components
import Avatar from '@src/components/compatibility/Avatar'

// ** Utils
import { isUserLoggedIn, getUserData } from '@utils'

// ** Store & Actions
import { useDispatch } from 'react-redux'
import { msalInstance } from '@src/utility/auth/authProvider'

// ** Third Party Components
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'
import { Power } from 'react-feather'
import ReactGA from "react-ga4";


// ** Default Avatar Image
import defaultAvatar from '@src/assets/images/logo/user-logo.png'
import { AccountInfo } from '@azure/msal-browser'
import axios from 'axios'
import { Groups } from '@src/enums/groups'
import LoadSupportCenter from '@src/utility/scriptTags/LoadSupportCenter'

import { Settings } from 'react-feather'
import UserPreferenceModal from '../modals/UserPreferenceModal'
import { useAbility } from '@src/utility/context/AbilityContext'
import { Permissions } from '@src/enums/permissions'

const UserDropdown = () => {
  // ** Store Vars
  const dispatch = useDispatch();

  // ** State
  const [userData, setUserData] = useState<AccountInfo | null>(null);
  const [userAvatar, setUserAvatar] = useState<any>(defaultAvatar);
  const [userPreferenceOpen, setUserPreferenceOpen] = useState(false);
  const [userCompany, setUserCompany] = useState<string>("Logicalis");
  const ability = useAbility();

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn()) {
      let currentUserData = getUserData();
      setUserData(currentUserData);
      
      // TODO: Substitute with policy check     
      if (ability?.can(Permissions.FullControl, Groups.Internal)) { 
        LoadSupportCenter(() => {
          var DOMContentLoaded_event = document.createEvent('Event');
          DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
          window.document.dispatchEvent(DOMContentLoaded_event);
        });
      }
      // axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', { responseType: 'blob' })
      //   .then(o => {
      //     const url = window.URL || window.webkitURL;
      //     const blobUrl = url.createObjectURL(o.data);
      //     setUserAvatar(blobUrl)
      //   })
      //   .catch((error) => {
          
      //   });
    } 
  }, []);

  const handleLogout = (e) => {
    e.preventDefault(); 
    window.localStorage.setItem("isSalesRegionUpdated",'false');
    window.localStorage.setItem("sp_Filter","");
    msalInstance.logoutRedirect();
  };

  const handleUserPreferenceModal = () => {
    setUserPreferenceOpen(!userPreferenceOpen);
  }

   const handleGetUserCompany = () => {
    const account = msalInstance.getAllAccounts()[0]
    axios.get(`https://graph.microsoft.com/v1.0/users/${account?.idTokenClaims?.oid}?$select=companyName`)
    .then(
      (res) => {
        // TODO: Substitute with policy check
        return setUserCompany(res.data.companyName 
          ? res.data.companyName 
          : (ability?.can(Permissions.FullControl, Groups.Internal) && 'Logicalis') || '' 
        );
      }
    );
  }
  

  useEffect(() => {
    handleGetUserCompany()
  },[])

  return (
    <>
    
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
        <div className='user-nav d-flex'>
          <span className='user-company font-weight-bold'>{userCompany}</span>
          <span className='user-name'>{(userData && userData['name']) || 'Unkown'}</span>
        </div>
       
        <Avatar img={userAvatar} imgHeight='40' imgWidth='40' status='online' />
      </DropdownToggle>
      <DropdownMenu>
    
        <DropdownItem onClick={handleUserPreferenceModal} >
          <Settings  size={14} className='me-1'/>
          <span className='align-middle'>User Preference</span>
          <UserPreferenceModal isOpen={userPreferenceOpen} handelUserPreferenceModalOpen={handleUserPreferenceModal} />
        </DropdownItem>
        <DropdownItem tag={Link} to='/logout' onClick={handleLogout}>
          <Power size={14} className='me-1' />
          <span className='align-middle'>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
    </>
    
  )
}

export default UserDropdown
